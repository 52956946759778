@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("./fonts/gilroy-semibold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("./fonts/gilroy-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("./fonts/gilroy-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./fonts/gilroy-bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("./fonts/gilroy-light.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Heavy";
  src: url("./fonts/gilroy-heavy.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("./fonts/gilroy-regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-family: Lato, system-ui, sans-serif;
  }
  [type="text"],
  [type="email"],
  [type="password"],
  select,
  textarea {
    @apply shadow-md;
    @apply text-instant-teams-gray-D1;
    @apply border-none;
    @apply shadow-input-field;
    @apply rounded-md;
    @apply focus:border-instant-teams-blue-Main;
    @apply focus:ring-1;
    @apply px-3;
  }
}

/* Header titles are in Ubuntu, reference style guide
  everything else is in Lato */

@layer components {
  .header-title {
    font-family: Ubuntu, sans-serif;
  }

  .hazard-background {
    height: 30px;
    color: white;
    padding: 5px;
    background-image: repeating-linear-gradient(
      -55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px
    );
  }
}

::-webkit-scrollbar {
  width: 0.7rem;
}

::-webkit-scrollbar-track {
  /* background: #fff; */
  background: #f6f7f8;
  border-radius: 100vw;
  margin-block: 1rem;
  box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.11);
  /* border: 1px solid rgba(255, 255, 255, 1); */
}

/* ::-webkit-scrollbar-thumb {
  background-color: hsl(120 100% 20% / 1);
  border: 0.25px solid hsl(120 75% 50% 1);
  border-radius: 100vw;
} */

::-webkit-scrollbar-thumb:hover {
  background: rgb(221, 218, 218);
  cursor: grab;

  border-radius: 8px;
  border: 3px solid transparent; /* Adds space for shadow */
  background-clip: padding-box; /* Prevents background from bleeding into the border */
}

/* ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-button {
  height: 0;
} */
::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  /* border: 3px solid white; */
  /* border: 3px solid rgba(255, 255, 255, 0.7); */
  /* box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.11); */
  /* background-color: rgba(237, 237, 237, 1); */
  background-color: #ededed;
  /* background-color: white; */

  box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.11);

  /* background-color: #888; */
  border-radius: 8px;
  border: 3px solid transparent; /* Adds space for shadow */
  background-clip: padding-box; /* Prevents background from bleeding into the border */
}

/* table {
  table-layout: fixed;
} */

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
th,
td {
  padding: 0.25rem;
}
th {
  background: white;
  position: sticky;
  top: 0;
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.inp {
    border:none;
    border-bottom: 2px solid #D9D9D9;
    padding: 5px 10px;
    outline: none;
 }

 .custom-dropdown {
    color: #D9D9D9;
  }
  
  .custom-dropdown option {
    color: #15415E;
  }
  
  .custom-dropdown option:disabled {
    color: #D9D9D9;
  }
  .PhoneInputInput{
    border: none;
  }

/* [placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease; 
    text-indent: -100%;
    opacity: 1;
 } */
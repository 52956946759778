.new-ui-input {
  border: none;
  border-bottom: 2px solid #d9d9d9;
  padding: 5px 10px;
  padding-left: 5px;
  outline: none;
}

.new-ui-input-with-suffix-text {
  border: none;
  border-bottom: 2px solid #d9d9d9;
  padding: 5px 10px;
  padding-left: 5px;
  padding-right: 50%;
  outline: none;
}

.new-ui-select {
  color: #d9d9d9;
}

.new-ui-select option {
  color: #15415e;
}

.new-ui-select option:disabled {
  color: #d9d9d9;
}
